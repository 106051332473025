import prestashop from 'prestashop';
import { onReady } from './utils';

const PRODUCT_REPLACE_MAP = {
    product_cover_thumbnails: '.js-product-cover-thumbnails',
    product_images_modal: '.js-product-images-modal',
    product_prices: '.js-product-prices',
    product_customization: '.js-product-customization',
    product_variants: '.js-product-variants',
    product_discounts: '.js-product-discounts',
    product_additional_info: '.js-product-additional-info',
    product_details: '.js-product-details',
    product_flags: '.js-product-flags',
    product_add_to_cart: '.js-product-add-to-cart',
};

const PRODUCT_CUSTOMIZATION_SIZE_REPLACE_MAP = {
    product_discounts: '.js-product-discounts',
    product_additional_info: '.js-product-additional-info',
    product_details: '.js-product-details',
    product_add_to_cart: '.js-product-add-to-cart',
};

let controller = new AbortController();

const getFormData = () => {
    const formEl = document.querySelector<HTMLFormElement>('.js-product-form');
    const qtyEl = formEl?.querySelector<HTMLInputElement>('#quantity_wanted');

    if (formEl == null || qtyEl == null) {
        throw new Error();
    }

    const formData = new FormData(formEl);
    const body = new URLSearchParams();
    [...formData.entries()].forEach(([key, value]) => {
        body.append(key, value.toString());
    });

    return {
        quantity: qtyEl.value,
        formAction: formEl.action,
        formData,
        body,
    };
};

async function updateProduct(eventType: string) {
    controller.abort();
    controller = new AbortController();

    const { body, quantity } = getFormData();
    const url = new URL(prestashop.urls.pages.product);

    Object.entries({
        ajax: '1',
        action: 'refresh',
        quantity_wanted: quantity,
    }).forEach(([key, value]) => {
        body.append(key, value.toString());
    });

    try {
        const response = await fetch(url.toString(), {
            signal: controller.signal,
            method: 'POST',
            body,
        });

        if (!response.ok) {
            alert('An error occurred while processing your request');
            return;
        }

        const data = await response.json();
        Object.entries(
            eventType == 'updatedProductSizeCustomization'
                ? PRODUCT_CUSTOMIZATION_SIZE_REPLACE_MAP
                : PRODUCT_REPLACE_MAP,
        )
            .filter(([key]) =>
                eventType == 'updatedProductQuantity'
                    ? key == 'product_add_to_cart'
                    : true,
            )
            .map(([key, el]) => [data[key], document.querySelector(el)])
            .filter(([data, el]) => data && el)
            .forEach(([data, el]) => {
                el.outerHTML = data;
            });
        prestashop.emit('updatedProduct', data);
    } catch (err) {
        console.log(err);
    }
}

async function addProduct() {
    controller.abort();
    controller = new AbortController();

    const { body, formAction } = getFormData();
    const url = new URL(formAction);

    Object.entries({
        add: '1',
        action: 'update',
    }).forEach(([key, value]) => {
        body.append(key, value.toString());
    });

    try {
        const response = await fetch(url.toString(), {
            headers: {
                Accept: 'application/json',
            },
            signal: controller.signal,
            method: 'POST',
            body,
        });

        if (!response.ok) {
            alert('An error occurred while processing your request');
            return;
        }

        const data = await response.json();
        prestashop.cart = data.cart;
        prestashop.emit('updateCart', {
            reason: {
                idProduct: data.id_product,
                idProductAttribute: data.id_product_attribute,
                idCustomization: data.id_customization,
                linkAction: 'add-to-cart',
                cart: data.cart,
            },
            resp: data,
        });
    } catch (e) {
        console.log(e);
    }
}

onReady(() => {
    window.addEventListener('update-product-combination', () => {
        prestashop.emit('updateProduct', {
            eventType: 'updatedProductCombination',
        });
    });

    window.addEventListener('update-product-quantity', () => {
        prestashop.emit('updateProduct', {
            eventType: 'updatedProductQuantity',
        });
    });

    window.addEventListener('update-product-size-customization', () => {
        prestashop.emit('updateProduct', {
            eventType: 'updatedProductSizeCustomization',
        });
    });

    window.addEventListener('add-product', () => {
        addProduct();
    });

    prestashop.on('updateProduct', ({ eventType }) => {
        updateProduct(eventType);
    });

    prestashop.on('updatedProduct', (args) => {
        const { product_url, id_product_attribute, product_title } = args;
        if ([product_url, id_product_attribute].some((v) => !v)) {
            return;
        }

        document.title = product_title ?? document.title;
        window.history.replaceState(
            {
                id_product_attribute,
            },
            document.title,
            product_url,
        );
    });
});
