import $ from 'jquery';

import '@/migrate-mute';
import 'jquery-migrate';
import 'jquery.browser';
import '@prestashop-core/jquery.live-polyfill';

import '@/selectors';
import '@/cart';
import '@/checkout';
import '@/facets';
import '@/listing';
import '@/product';
import '@/address';

import prestashop from 'prestashop';
import { EventEmitter } from 'events';

import { onReady } from '@/utils';
import { psShowHide } from '@/common';
import initEmailFields from '@/email-idn';

window.$ = $;
window.jQuery = $;

// "inherit" EventEmitter
Object.assign(prestashop, EventEmitter.prototype);

onReady(() => {
    psShowHide();
    initEmailFields('input[type="email"]');
});
