import prestashop from 'prestashop';
import { TUpdateCartReason } from '~types/common';
import { onReady } from './utils';
import { refreshCheckoutPage } from './common';

const CART_REPLACE_MAP = {
    cart_detailed: '.js-cart-detailed',
    cart_detailed_actions: '.js-cart-detailed-actions',
    cart_detailed_totals: '.js-cart-detailed-totals',
    cart_summary_items_subtotal: '.js-cart-summary-items-subtotal',
    cart_summary_subtotals_container: '.js-cart-summary-subtotals-container',
    cart_summary_totals: '.js-cart-summary-totals',
    cart_voucher: '.js-cart-voucher',
};

let controller = new AbortController();

async function updateCart(reason: TUpdateCartReason) {
    controller.abort();
    controller = new AbortController();

    prestashop.cart = reason.cart;

    const cartEl = document.querySelector<HTMLElement>('.js-cart');
    const refreshUrl = cartEl?.dataset.refreshUrl;

    if (cartEl == null || refreshUrl == undefined) {
        return;
    }

    const url = new URL(refreshUrl, window.location.origin);
    const body = new URLSearchParams({
        id_product_attribute: reason.idProductAttribute,
        id_product: reason.idProduct,
    });

    try {
        const response = await fetch(url.toString(), {
            signal: controller.signal,
            method: 'POST',
            body,
        });

        if (!response.ok) {
            alert('An error occurred while processing your request');
            return;
        }

        const data = await response.json();
        Object.entries(CART_REPLACE_MAP)
            .map(([key, el]) => [data[key], document.querySelector(el)])
            .filter(([data, el]) => data && el)
            .forEach(([data, el]) => {
                el.outerHTML = data;
            });

        if (document.querySelector('.js-cart-payment-step-refresh')) {
            // we get the refresh flag : on payment step we need to refresh page to be sure
            // amount is correctly updated on payment modules
            refreshCheckoutPage();
        }

        prestashop.emit('updatedCart', {
            eventType: 'updateCart',
            resp: data,
        });
    } catch (err) {
        console.log(err);
    }
}

async function addVoucher(form: HTMLFormElement) {
    const body = new FormData(form);
    const url = new URL(
        form.getAttribute('action') ?? '',
        window.location.origin,
    );

    try {
        const response = await fetch(url.toString(), {
            signal: controller.signal,
            method: 'POST',
            body,
        });

        if (!response.ok) {
            alert('An error occurred while processing your request');
            return;
        }

        const data = await response.json();
        const errorEl = document.querySelector('.js-error-text');
        if (data.hasError && errorEl) {
            errorEl.innerHTML = data.errors[0];
            return;
        }

        prestashop.emit('updateCart', {
            reason: form.dataset,
            resp: data,
        });
    } catch (err) {
        console.log(err);
    }
}

onReady(() => {
    window.addEventListener('add-voucher', (event) => {
        addVoucher(event.target as HTMLFormElement);
    });

    prestashop.on('updateCart', ({ reason }) => {
        updateCart(reason);
    });
});
